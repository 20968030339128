import React from "react";
import ContactUs from "../ContactUs/ContactUs";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import { OurPurpose } from "../OurPurpose/OurPurpose";

export default class Home extends React.Component {
 
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}

        <OurPurpose />

        {/* Contact Us */}
        <ContactUs />
      
      </React.Fragment>
    );
  }
}
