import React from "react";
import "./WhoWeAre.scss";

const WhoWeAre = () => {
  return (
    <div className="whoweare">
      {/* <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{ fontSize: "55px" }}>Who We Are</h1>
          </div>
        </section>  */}
      <div className="content">
        <div className="header-text">
          <h1>Who We Are</h1>
        </div>
        <h2>
          WW Minerals is a part of WW International Group whose history backs in
          the year 1950 who traditionally have been traders in speciality
          minerals like coal, iron, gold and bronze.
        </h2>
      </div>
    </div>
  );
};

export default WhoWeAre;
