import React from 'react'
import "./OurPurpose.scss"

export const OurPurpose = () => {
    return (
        <div className="purpose">
         {/* <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{ fontSize: "55px" }}>Our Purpose And Values</h1>
          </div>
        </section>  */}
          <div className="content">
            <div className="header-text">
              <h1>Our Purpose And Values</h1>
            </div>
            <h2>
            Our purpose is to right source and deliver value to our customer. Our values, continued commitment to our purpose and our focus will help WW Minerals to take on any challenge with resilience and will allow us to stay focussed on what we do best right source and deliver value to our customers.
            </h2>
          </div>
        </div>
    )
}
