import React from "react";
import { withRouter } from "react-router";
import InputBox from "../../Components/InputBox/InputBox";
import { Row, Col, Button, Select } from "antd";
import "./Contactus.scss";
import { Input } from "antd";

const { TextArea } = Input;

class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultActiveKey: [],
      mobileNumber: "",
      fullName: "",
      emailId: "",
      phoneNumber: "",
      location: "",
    };
  }

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="contact-us">
          <div className="Contact-head">
            <h1 style={{ fontSize: "45px" }}>Contact Us</h1>
          </div>

          <Row gutter={[12, 20]}>
            <Col
              style={{ paddingLeft: "30px" }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              className="Contact-right"
            >
              <form>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Full Name"
                    id="fullName"
                    value={this.state.fullName}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Email"
                    id="emailId"
                    value={this.state.emailId}
                    onChangeText={this.onChangeText}
                    type="email"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Phone Number"
                    id="phoneNumber"
                    value={this.state.phoneNumber}
                    onChangeText={this.onChangeText}
                    type="tel"
                  />
                </div>
                <div className="form-fields">
                  <Select
                    placeholder="Select Sector"
                    allowClear
                    style={{width:"100%",borderRadius:"5px",}}
                  >
                    <option value="Government">Government</option>
                    <option value="Commercial">Commercial</option>
                    <option value="other">other</option>
                  </Select>   
                </div>
                <div className="form-fields">
                  <Select
                    placeholder="Select Product"
                    allowClear
                    style={{width:"100%",borderRadius:"5px",}}
                  >
                    <option value="Rare Earth Metals">Rare Earth Metals</option>
                    <option value="Gold">Gold</option>
                    <option value="Coal Energy">Coal Energy</option>
                    <option value="Mettalurgical Coal">Mettalurgical Coal</option>
                    <option value="Petroleum">Petroleum</option>
                    <option value="Potash">Potash</option>
                    <option value="Limestone">Limestone</option>
                    <option value="Diamond">Diamond</option>
                    <option value="Iron Ore">Iron Ore</option>
                    <option value="Aluminium">Aluminium</option>
                    <option value="Nickel">Nickel</option>
                    <option value="Copper">Copper</option>
                    <option value="Tungsten">Tungsten</option>
                    <option value="Tin">Tin</option>
                    <option value="Industrial Salt">Industrial Salt</option>
                    <option value="Magnesite">Magnesite</option>
                    <option value="Bentonite">Bentonite</option>
                    <option value="Gypsum">Gypsum</option>
                    <option value="Graphite">Graphite</option>
                    <option value="Graphene">Graphene</option>
                    <option value="Quartz">Quartz</option>
                  </Select>   
                </div>
                <div className="form-fields">
                <Select
                    placeholder="mode of payment"
                    allowClear
                    style={{ width:"100%", borderRadius:"5px"}}
                  >
                    <option value="LC">LC</option>
                    <option value="Wire transfer">Wire transfer</option>
                    <option value="other">other</option>
                  </Select>
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Location Prefernce"
                    id="location"
                    value={this.state.location}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>

                <div className="form-fields">
                  <TextArea rows={4} placeholder="Comments" />
                </div>
                <div className="form-fields">
                  <Button type="primary">Submit</Button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);
