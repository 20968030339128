import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import ContactUs from "../Screens/ContactUs/ContactUs";
import PrivacyPolicy from "../Screens/policy/PrivacyPolicy";
import TermsAndConditions from "../Screens/policy/TermsAndConditions";
import Layout from "../Layout/Layout";

import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { OurPurpose } from "../Screens/OurPurpose/OurPurpose";
import WhoWeAre from "../Screens/WhoweAre/WhoWeAre";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/ourpurposeandvalues`} component={OurPurpose} />
          <Route exact path={`/whoweare`} component={WhoWeAre} />
          <Route exact path={`/signup`} component={SigninSignup} />
          <Route exact path={`/contact`} component={ContactUs} />
          <Route exact path={`/privacypolicy`} component={PrivacyPolicy} />
          <Route exact path={`/termsandconditions`} component={TermsAndConditions}
          />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
